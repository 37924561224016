<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable :value="products" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<div>
								<Dropdown id="state" v-model="$store.state.year" :options="$store.state.years" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." @change="$store.state.mes=nul" ></Dropdown>
								<Dropdown id="state" v-model="$store.state.mes" :options="$store.state.meses" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." @change="Buscar" ></Dropdown>
							</div>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filtro" placeholder="Buscar..." @keyup="Filtrar"/> Total: {{products.length}}
                            </span>
							<Button label="Pagos Pendientes" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="pendDialog=true" v-if="insert"/>
						</div>
					</template>
					<Column field="id" header="N°" :sortable="true" headerStyle="width: 50px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="fechad" header="Fecha" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="cedula" header="N° Cédula" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.cedula}}
						</template>
					</Column>
					<Column field="cliente" header="Cliente" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cliente}}
						</template>
					</Column>
					<Column field="montobs" header="Monto" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							Bs. {{slotProps.data.montobs}}
						</template>
					</Column>
					<Column header="Acciones" headerStyle="width: 180px">
						<template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary mr-2" @click="VerPDF(slotProps.data)"/>
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="VerDetalle(slotProps.data)" v-tooltip.bottom="'Editar'" v-if="edit"/>
							<Button icon="pi pi-envelope" class="p-button-rounded p-button-success mr-2" @click="Enviar(slotProps.data)" v-tooltip.bottom="'Enviar'" v-if="edit"/>
						</template>
					</Column>
					<Column header="Factura" headerStyle="width: 70px">
						<template #body="slotProps">
							{{slotProps.data.control}}
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<Dialog v-model:visible="pendDialog" :style="{width: size}" header="Pendientes" :modal="true" class="p-fluid" :maximizable="true">
				<DataTable :value="pendientes" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        selectionMode="single" @rowSelect="onRowSelect" :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<Column field="cedula" header="N° Cédula" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.cedula}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="celular" header="N° Celular" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.celular}}
						</template>
					</Column>
					<Column field="deuda" header="Monto" headerStyle="width: 150px">
						<template #body="slotProps">
							$ {{slotProps.data.deuda}}
						</template>
					</Column>
					<Column header="Acciones" headerStyle="width: 150px">
						<template #body="slotProps">
							<Button icon="pi pi-play" class="p-button-rounded p-button-success mr-2" @click="Editar(slotProps.data)" v-if="edit"/>
						</template>
					</Column>
				</DataTable>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="pendDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="productDialog" :style="{width: size}" header="Procesar Pago" :modal="true" class="p-fluid" :maximizable="true">
			<Toast/>
			<div class="field">
				<div class="grid">
					<div class="col-12">
						<Card>
							<template #content>
								<div class="fluid formgrid grid">
									<div class="col-8">
										<h5>Cliente:  {{(product.nombre+' '+product.apellido)}}</h5>
									</div>
                                    <div class="col-4">
										<h5>Cédula: {{product.cedula}}</h5>
									</div>
									<div class="col-8">
										<h5>Dirección:  {{(product.direccion)}}</h5>
									</div>
                                    <div class="col-4">
										<h5>Celular: {{product.celular}}</h5>
									</div>
									<div class="col-12">
										<DataTable :value="product.detalle" responsiveLayout="scroll" dataKey="id" >
											<Column field="fechad" header="Fecha"></Column>
											<Column field="especialidad" header="Especialidad"></Column>
											<Column field="producto" header="Descripción"></Column>
											<Column field="monto" header="Monto" headerStyle="width: 50px">
												<template #body="slotProps">
													{{(slotProps.data.abrev+slotProps.data.monto)}}
												</template>
											</Column>
											<Column field="sec" header="Secuencial" headerStyle="width: 50px">
												<template #body="slotProps">
													<InputNumber id="sec" v-model="slotProps.data.sec" integeronly min="0"/>
												</template>
											</Column>
										</DataTable>
									</div>

								</div>
							</template>
							<template #footer>	
								Total a Pagar: ${{product.deuda}}
							</template>
						</Card>	
					</div>
				</div>
				<ReportarPago :tipopago="tipopago" :deuda="totales" :moneda="product.moneda"/>

			</div>
			<template #footer>
				<Button label="Procesar" icon="pi pi-check" class="p-button-secondary"  @click="Ejecutar()" :loading="$store.state.loading" :disabled="(parseFloat($store.state.pago.deuda).toFixed(2) > parseFloat($store.state.pago.monto).toFixed(2))"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="deleteDialog"  style="width: 30vw" :header="'Eliminar ID'+product.id" :modal="true">
            <div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres eliminar?</span>
			</div>
			<template #footer>
				<Button label="Si" icon="pi pi-check" class="p-button-secondary" @click="Eliminar" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="DetalleDialog" :style="{width: size}" header="Detalles" :modal="true" class="p-fluid" :maximizable="true">
			<DataTable :value="detalles" responsiveLayout="scroll" dataKey="id" >
				<Column field="fecha" header="Fecha"></Column>
				<Column field="especialidad" header="Especialidad"></Column>
				<Column field="producto" header="Descripción"></Column>
				<Column field="monto" header="Monto" headerStyle="width: 50px">
					<template #body="slotProps">
						{{(slotProps.data.abrev+slotProps.data.monto)}}
					</template>
				</Column>
				<Column field="sec" header="Secuencial" headerStyle="width: 50px">
					<template #body="slotProps">
						<InputNumber id="sec" v-model="slotProps.data.sec" integeronly min="0"/>
					</template>
				</Column>
			</DataTable>
			<template #footer>
				<Button label="Guardar" icon="pi pi-play" class="p-button-success" @click="GuadDetalle"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Administracion','Recibo');
	import ReportarPago from '@/components/ReportarPago.vue';
	import PDF from "../service/PDF";

	export default {
		components: {
			ReportarPago,
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				DetalleDialog: false,
				product: {},
				products: [],
				resultado: [],
				pendDialog: false,
				productDialog: false,
				deleteDialog: false,
				selectedCustomers: null,
				filtro: null,
				filters: {},
				size: '60vw',
                tipopago:[],
				pendientes: [],
                totales: {
                    subtotal: 0,
                    descuento: 0,
                    iva: 0,
					final: 0,
					finalbs: 0,
                },
				detalles: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			onRowSelect(event) {
				this.Editar(event.data);
			},
			Mostrar() {
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.pendientes = response.result.pendientes;
                    this.tipopago = response.result.tipopago;
					//this.products = response.result.facturas;
					this.$store.commit('Loading');
					this.Buscar();
				}); 
			},
			Buscar(){
                if(this.opcion){
					let opciones = {
						mes: this.$store.state.mes.id,
						year: this.$store.state.year.id,
					};
                    Consulta.Procesar('BuscarFact',{
                        product: opciones
                    }).then(response => {
                        //this.$store.state.error = response.result[0];
                        this.products = response.result;
						this.resultado = response.result;
						this.Filtrar();
                        this.$store.commit('Loading');
                    });
                } 
            },
			Filtrar() {
				if (this.filtro==null || this.filtro==''){
					this.products = this.resultado;
				} else {
					this.products = this.resultado.filter(val => 
						val.cliente.toUpperCase().includes(this.filtro.toUpperCase())
					);	
				}
			},
			VerPDF(data){
				Consulta.Procesar('VER',{
						product: data,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						if (response.result) {
							data.detalle = response.result;
							const doc = new PDF();
							doc.PreFactura(data);
						}
					});
			},
			VerDetalle(data){
				Consulta.Procesar('VER',{
						product: data,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						if (response.result) {
							this.detalles = response.result;
							this.DetalleDialog = true;
						}
					});
			},
			Enviar(data){
				Consulta.Procesar('Enviar',{
						product: data,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
					});
			},
			GuadDetalle() {
				Consulta.Procesar('Detalle',{
						product: this.detalles,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.DetalleDialog = false;
						}
					});
			},
			Editar(product) {
				this.nuevo = false;
				this.product = {...product};
				this.product.moneda =  this.$store.state.monedas[0];
				this.totales.final = parseFloat(product.deuda);
				this.pendDialog = false;
				Consulta.Procesar('Buscar',{
						product: this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.product.detalle = response.result;
							this.productDialog = true;
						}
					});
			},
			Ejecutar() {
				if (!this.product == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione', life: 5000});
				} else if (this.$store.state.pago.monto==0 && this.$store.state.pago.montobs==0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la forma de pago', life: 10000});
				} else {
					this.product.pagos = this.$store.state.pago;
					this.product.tasa = this.$store.state.tasa;
					Consulta.Procesar('Procesar',{
						product: 	this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.$store.commit('LimpiarPagos');
							this.productDialog = false;
							this.Mostrar();
						} 
					});
				}
            },
			Confirmar(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
                    //this.$store.state.error = response;
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
